<!--
 * @Author: snltty
 * @Date: 2021-10-11 11:04:50
 * @LastEditors: snltty
 * @LastEditTime: 2021-10-11 15:26:29
 * @version: v1.0.0
 * @Descripttion: 功能说明
 * @FilePath: \pc\src\views\Product.vue
-->
<template>
    <div class="product">
        <div class="item">
            <div class="img">
                <img src="images/6.jpg" alt="">
                <div class="texts">
                    <h3>一：奇贝智能实物编程积木</h3>
                    <p>1：编程代码实物化，让编程代码看得见，摸得着，涵盖编程基础概念顺序、循环、判断、逻辑运算等20多个功能块。</p>
                    <p>2：编程代码智能化，搭载蓝牙芯片、计算芯片，让智能成为可能，实现一键发送，实时显示执行结果。</p>
                    <p>3：编程代码积木化，编程积木自由组合，拼接出任意组合代码，编程随心所欲。</p>
                    <p>4：对视力0伤害，脱离电脑或手机也能学编程，让4-7岁幼儿学习编程更简单，学习编程更直观。</p>
                </div>
                <div class="texts-2">
                    <el-card>
                        <h3>一：奇贝智能实物编程积木</h3>
                        <p>1：编程代码实物化，让编程代码看得见，摸得着，涵盖编程基础概念顺序、循环、判断、逻辑运算等20多个功能块。</p>
                        <p>2：编程代码智能化，搭载蓝牙芯片、计算芯片，让智能成为可能，实现一键发送，实时显示执行结果。</p>
                        <p>3：编程代码积木化，编程积木自由组合，拼接出任意组合代码，编程随心所欲。</p>
                        <p>4：对视力0伤害，脱离电脑或手机也能学编程，让4-7岁幼儿学习编程更简单，学习编程更直观。</p>
                    </el-card>
                </div>
            </div>
        </div>
        <div class="item">
            <div class="img">
                <img src="images/1.jpg" alt="">
                <div class="texts">
                    <h3>二：智能终端机器人</h3>
                    <p>1：更直观，执行智能积木自由组合后的代码，让编程过程及结果每一步都可以完美呈现。</p>
                    <p>2：更智能，具备机器人所特有的特征，可检测障碍物、颜色、温度、光纤、声音等，让小朋友们走进科技，了解科技，学习科技。</p>
                    <p>3：更开放，车体设计了兼容中号颗粒积木，自由搭建各类构造物体，训练创新思维，空间思维。</p>
                </div>
                <div class="texts-2">
                    <el-card>
                        <h3>二：智能终端机器人</h3>
                        <p>1：更直观，执行智能积木自由组合后的代码，让编程过程及结果每一步都可以完美呈现。</p>
                        <p>2：更智能，具备机器人所特有的特征，可检测障碍物、颜色、温度、光纤、声音等，让小朋友们走进科技，了解科技，学习科技。</p>
                        <p>3：更开放，车体设计了兼容中号颗粒积木，自由搭建各类构造物体，训练创新思维，空间思维。</p>
                    </el-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup () {

        return {}
    }
}
</script>

<style lang="stylus" scoped>
@media screen and (max-width: 768px)
    .item
        .texts
            display: none !important;

        .texts-2
            display: block !important;

.item
    position: relative;

    .img
        position: relative;
        font-size: 0;

        img
            width: 100%;

    .texts-2
        display: none;
        color: #333;
        margin: -4rem 1rem 1rem 1rem;
        position: relative;

        h3
            font-size: 1.6rem;

        p
            font-size: 1.4rem;
            margin-top: 1rem;

    .texts
        position: absolute;
        left: 2rem;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 1rem;
        max-width: 80%;

        h3
            font-size: 2rem;

        p
            font-size: 1.6rem;
            margin-top: 1rem;
</style>